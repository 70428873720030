/// <reference path="../../../typings/jquery/jquery.d.ts"/>
/// <reference path="../../../typings/materialize-css/materialize-css.d.ts"/>

class LocationModal {
    private form;
    private location;
    private locationId;
    private modal;
    private callback: Function;
    private lvLocation: LifeVineLocations;

    constructor(lvLocation: LifeVineLocations, locationId, callback: Function) {
        this.lvLocation = lvLocation;
        this.locationId = locationId;
        this.callback = callback;
        this.modal = jQuery('#location_modal').modal();
        this.form = this.modal.find('form');

        if (locationId) {
            lvLocation.get(locationId)
                .done(data => {
                    this.location = data;
                    this.show();
                });
        } else {
            this.location = {
                line1: '',
                line2: '',
                city: '',
                state: 'NY',
                zip: '',
                country: 'US'
            };

            this.show();
        }
    }

    show() {
        setFormData(this.form, this.location);
        this.modal.modal('open');
        Materialize.updateTextFields();
        this.modal.find('#location_modal_save').off('click').click(event => this.save(event));
        this.modal.find('#location_modal_cancel').off('click').click(event => this.cancel(event));
    }

    save(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        let formData = getFormData(this.form);

        if (this.locationId) {
            this.lvLocation.save(this.locationId, formData)
                .done(data => {
                    if (data.success === true) {
                        this.modal.modal('close');
                        this.callback();
                    } else {
                        this.setErrors(data.errors);
                    }
                });
        } else {
            this.lvLocation.create(formData)
                .done(data => {
                    if (data.success === true) {
                        this.modal.modal('close');
                        this.callback();
                    } else {
                        this.setErrors(data.errors);
                    }
                });
        }
    }

    cancel(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        this.modal.modal('close');
    }

    setErrors(errors) {
        jQuery.each(errors, (field, message) => {
            this.form.find(`[for="${field}"]`).attr('data-error', message);
            this.form.find(`#${field}`).removeClass('valid').addClass('invalid');
        });
    }
}